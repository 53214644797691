import { marketPlace } from '@/axios'

export default {
  changeOrderStatus (id, status) {
    return marketPlace().patch(`merchant/orders/${id}`, { status: status })
  },
  getOrder (id) {
    return marketPlace().get(`merchant/orders/${id}`)
  },
  downloadInvoice (id) {
    return marketPlace().post('invoice/order/' + id)
  }
}
