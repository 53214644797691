<template>
  <div>
    <dashboard-page-title :showMainActions="false">
      {{ $t("orders.orderDetails") }}
    </dashboard-page-title>

    <div>
      <b-tabs content-class="mt-3" active>
        <b-tab :title="$t('main.generalInformation')">
          <div class="general-information">
            <b-row>
              <b-col md="6" class="mb-3">
                <input-form
                  :placeholder="$t('orders.order_num')"
                  :label="$t('orders.order_num')"
                  v-model="order.order_number"
                  :disabled="true"
                />
              </b-col>
              <b-col md="6" class="mb-3">
                <input-form
                  :placeholder="$t('main.status.status')"
                  :label="$t('main.status.status')"
                  :value="$t(`main.status.${order.status}`)"
                  :disabled="true"
                />
              </b-col>

              <b-col md="4" class="mb-3">
                <input-form
                  :placeholder="$t('main.sub_total')"
                  :label="$t('main.sub_total')"
                  v-model="order.sub_total_amount"
                  :disabled="true"
                />
              </b-col>
              <b-col md="4" class="mb-3">
                <input-form
                  :placeholder="$t('main.tax_amount')"
                  :label="$t('main.tax_amount')"
                  v-model="order.tax_amount"
                  :disabled="true"
                />
              </b-col>
              <b-col md="4" class="mb-3">
                <input-form
                  :placeholder="$t('main.shipping_amount')"
                  :label="$t('main.shipping_amount')"
                  v-model="order.shipping_amount"
                  :disabled="true"
                />
              </b-col>
              <b-col md="4" class="mb-3">
                <input-form
                  :placeholder="$t('main.discount_amount')"
                  :label="$t('main.discount_amount')"
                  v-model="order.discount_amount"
                  :disabled="true"
                />
              </b-col>

              <b-col md="4" class="mb-3">
                <input-form
                  :placeholder="$t('main.total_amount')"
                  :label="$t('main.total_amount')"
                  v-model="order.total_amount"
                  :disabled="true"
                />
              </b-col>

              <b-col md="6" class="mb-3">
                <input-form
                  :placeholder="$t('main.payment_method')"
                  :label="$t('main.payment_method')"
                  :value="
                    $t(`orders.${order.payment_method_type.toLowerCase()}`)
                  "
                  :disabled="true"
                />
              </b-col>
              <b-col md="6" class="mb-3">
                <input-form
                  :placeholder="$t('main.created_at')"
                  :label="$t('main.created_at')"
                  :value="
                    new Intl.DateTimeFormat('en-US').format(
                      new Date(order.created_at)
                    )
                  "
                  :disabled="true"
                />
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab :title="$t('products.products')">
          <main-table
            :fields="fields"
            :items="order.orderItems"
            :paginationName="$t('products.products')"
          ></main-table>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import orderService from "../services/order";

export default {
  components: {},

  data() {
    return {
      id: this.$route.params.id,
      order: null,
      fields: [
        {
          label: this.$t("products.mainImage"),
          key: "product.featured_image",
          type: "image",
        },
        { label: this.$t("products.name"), key: "product.title" },
        {
          label: this.$t("products.categories.category"),
          key: "product.category.name",
        },
        { label: this.$t("main.quantity"), key: "quantity" },
        { label: this.$t("main.final_price"), key: "total" },
      ],
    };
  },
  methods: {
    getOrder() {
      orderService
        .getOrder(this.id)
        .then((res) => {
          this.order = res.data.data;
        })
        .catch((err) => console.log("err", err));
    },
  },
  created() {
    this.getOrder();
  },
};
</script>
